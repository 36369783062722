.contactSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #e2e2e2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.theTitle {
  font-size: 70px !important;
  font-weight: 900 !important;
}
.emailTitle {
  margin-top: 40px;
}
