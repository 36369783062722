.headerSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blackOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.headerText {
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 768px) {
}
