@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;700;900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393939 !important;
  background-color: #fff !important;
  text-align: left;
  font-weight: 400;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 900 !important;
  letter-spacing: 2px;
}

h1 {
  font-size: 48px !important;
}

h2 {
  font-size: 34px !important;
}

h3 {
  font-size: 22px !important;
}

h4 {
  font-size: 18px !important;
}

p {
  font-size: 16px !important;
}

.smallText {
  font-size: 12px !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.verticalAlign {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.noPadding {
  padding: 0 !important;
}

.padding {
  padding: 25px !important;
}

.smallPadding {
  padding: 5px !important;
}

.paddingTop {
  padding-top: 60px !important;
}

.bigPaddingTop {
  padding-top: 120px !important;
}

.bigPaddingBottom {
  padding-bottom: 120px !important;
}

.smallPaddingTop {
  padding-top: 20px !important;
}

.paddingSides {
  padding-left: 10vw !important;
  padding-right: 10vw !important;
}

.paddingSidesPx {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.marginTop {
  margin-top: 90px !important;
}

.bigMarginTop {
  margin-top: 120px !important;
}

.smallMarginTop {
  margin-top: 20px !important;
}

.smallAllMargin {
  margin: 20px;
}

.autoMargin {
  margin: auto !important;
}

.row {
  margin-left: auto !important;
  margin-right: auto !important;
}

.countNavbar {
  padding-top: 60px !important;
}

.width100 {
  width: 100% !important;
}

.height100 {
  height: 100vh;
}

.backgroundBlack {
  background-color: black;
}

.backgroundWhite {
  background-color: #fff !important;
}

.colorWhite {
  color: #ffffff !important;
}

.colorBlack {
  color: #000000 !important;
}

.colorOrange {
  color: #fca846 !important;
}

.colorRed {
  color: #f42929 !important;
}

.textBold {
  font-weight: 900 !important;
}

.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.btnInactive {
  opacity: 0.5;
  cursor: default !important;
}

.cursorPointer {
  cursor: pointer;
}

.lastDiv {
  height: 100px;
}

.hideMobile {
  display: block;
}

.showMobile {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: block !important;
  }
}

@media only screen and (max-width: 960px) {
  h1,
  h2,
  h3,
  h4 {
    letter-spacing: 1px;
  }

  h1 {
    font-size: 24px !important;
  }

  h2 {
    font-size: 18px !important;
  }

  h3 {
    font-size: 14px !important;
  }

  h4 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
}
