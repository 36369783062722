.gameSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 20px 20px 120px !important;
}
.gameTextDiv {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.gameTextContent {
  padding: 0px 60px !important;
}
.gameLogo {
  width: 75%;
  max-width: 300px;
}
.gameDescription {
  margin: 30px auto;
}
.gameButton {
  color: white;
  background-color: #5162ff;
  padding: 10px 20px !important;
  font-size: 20px;
  font-weight: 600;
  border: none !important;
  margin: 10px 20px 10px 0px;
  transition: all 0.51s;
}
.gameButton:hover {
  color: #5162ff;
  background-color: #fff;
}
.gameImageDiv {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameImage {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .gameSection {
    height: auto;
    padding: 80px 10px 120px 10px !important;
  }
}
