.aboutSection {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #e2e2e2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.theTeamTitle {
  font-size: 70px !important;
  font-weight: 900 !important;
}
.rowTeamMembers {
  position: relative;
  margin-top: 120px !important;
  width: 100%;
}

.memberBadge {
  position: relative;
  color: white;
  background-color: #51a1ff;
  width: 100%;
  padding: 20px 0px 0px 20px !important;
}
.memberText {
  position: relative;
  text-align: left !important;
}
.memberName {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px !important;
}
.memberNickname {
  font-weight: 400;
  font-style: italic;
  font-size: 18px !important;
}
.memberRole {
  font-weight: 400;
  font-size: 18px !important;
  text-transform: uppercase;
  position: absolute;
  bottom: 20px;
}
.memberImage {
  position: relative;
  text-align: right !important;
}
.memberProfile {
  width: 100%;
}
.memberDescription {
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .memberRole {
    position: relative;
  }
  .aboutSection {
    height: auto;
    padding: 100px 10px 100px 10px !important;
  }
}
