.customNavBarStyle {
  color: #7d7d7d !important;
  background-color: white !important;
  padding: 10px 20px;
  z-index: 20;
}

.navbar-brand img {
  height: 40px;
}

.customNavBarStyle-links a {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #7d7d7d !important;
  letter-spacing: 2px;
  margin: 0px 10px !important;
}

.customNavBarStyle-links a:hover {
  color: #5162ff !important;
}

.navbarToggle-Icon {
  color: #7d7d7d !important;
  font-size: 30px;
}

@media only screen and (max-width: 960px) {
  .customNavBarStyle {
    padding: 10px;
  }

  .customNavBarStyle-links a {
    font-size: 16px !important;
  }

  /* .customNavBarStyle-links {
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

    }

    .customNavBarStyle-links a {
        margin: 10px auto !important;

    } */
}
